$box-shadow: 0 .4em .8em rgba(0,0,0,.4);
$box-shadow-hovered: .2em .6em 1em rgba(0,0,0,.6);

* {
	box-sizing: border-box; }


html {
	height: 100%; }


body {
	--body-bg:       #487;
	--body-fg:       #000;
	--link-fg:       #368;
	--art-bg:        #eff;
	--header-bg:     #eff, #f3ffff, #c4e1dd;
	--minor-fg:      #244;
	--menu-bg:       #eff;
	--menu-hover-bg: #bcc;
	--box-bg:        #dee;
	--border-color:  #999;

	display: flex;
	flex-direction: column;
	min-height: 100%;
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiB2aWV3Qm94PSIwIDAgMjAgMjAiPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iMCIgeDI9IjAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLW9wYWNpdHk9Ii4yIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLW9wYWNpdHk9IjAiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cGF0aCBkPSJtMTAgMGgxMHYxMGgtMjB2MTBoMTB6IiBmaWxsPSIjNDc4ZjdhIi8+PHBhdGggZD0ibTAgMCAxMCAzIDEwLTN6IiBmaWxsPSJ1cmwoIzApIi8+PHBhdGggZD0ibTAgMTBoMjB2M2wtMTAtMy0xMCAzeiIgZmlsbD0idXJsKCMwKSIvPjwvc3ZnPg==') fixed var(--body-bg);
	color: var(--body-fg);
	font-family: sans-serif;
	margin: 0;

	>header {
		box-shadow: $box-shadow;
		background: var(--art-bg);
		background-image: linear-gradient(var(--header-bg));
		margin-bottom: 2em; }

	>footer {
		background: var(--art-bg);

		.banners {
			float: right;

			>* {
				display: inline-block;
				margin: 0 .1em; } } } }


main {
	flex: 1; }


h1 {
	font-size: 1.5em; }


a {
	color: var(--link-fg);
	text-decoration: none; }


.container {
	margin: auto;
	width: 90%;
	max-width: 75em; }


body[margins="thin"] {
	.container {
		width: 95%;
		max-width: none; } }


.page-title {
	display: flex;
	align-items: center;
	font-weight: bold;

	img {
		padding: 0 .5em; }

	a {
		display: block;
		margin-bottom: .2em; }

	.minor {
		font-size: .6em; }

	.left {
		flex: 1;
		font-size: 2em; }

	.right {
		margin: 0;
		text-align: right;
		font-size: 1.2em; } }


footer {
	padding: .7em 0; }


.details {
	color: var(--minor-fg);
	font-size: 80%; }


.minor {
	color: var(--minor-fg);
	font-size: 70%; }


.breadcrumb {
	color: #fff;
	font-size: 80%;
	font-weight: bold;
	text-shadow: 0 1px 3px rgba(0,0,0,.9);

	&::before {
		content: "▸";
		margin: 0 .5em; } }


time::before {
	content: "⌚ "; }


dt {
	font-weight: bold; }


article {
	background-color: var(--art-bg);
	box-shadow: $box-shadow;
	padding: 1px 1em 1em;
	margin: 1em 0;
	overflow: auto;
	hyphens: auto;

	table {
		margin: 1em 0;
		border-collapse: collapse;
		font-size: .9em; }

	thead, tfoot {
		background-color: var(--box-bg); }

	td, th {
		border: 1px solid var(--border-color);
		padding: .2em; }

	td {
		vertical-align: top; }

	td ul, td ol {
		margin: 0;
		padding-left: 1.5em; }

	figure {
		margin: 1em 0;
		text-align: center; }

	img {
		max-width: 100%; }

	header {
		border-bottom: 1px solid var(--border-color);

		h1, h2 {
			margin-bottom: 0; } }

	blockquote {
		font-style: italic;
		background-color: var(--box-bg);
		border-left: .3em solid var(--border-color);
		margin: 1em 0;
		padding: .3em 1ex;

		footer {
			color: var(--minor-fg);
			padding: 0; } }

	.epigraph {
		text-align: right;
		background-color: transparent;
		border: none; }

	.footnotes {
		border-top: 1px solid var(--border-color);
		font-size: 80%; }

	footer {
		clear: both;
		padding: 1em 0 0;
		font-size: 80%; }

	.tags {
		a:before {
			content: '🏷';
			margin: 0 .5em; } }

	details[open] {
		>summary {
			margin-bottom: 1em;
			border-bottom: 1px solid var(--border-color); } }

	summary {
		cursor: pointer;
		margin: -.5em -1em;
		padding: .3em; } }


nav.main {
	padding: .3em 0;

	form {
		float: right; }

	>div {
		display: inline-block;

		&:hover .dropdown-content {
			display: block; } }

	a {
		display: block;
		padding: .4em .8em;
		color: var(--body-fg);

		&:hover {
			background-color: var(--menu-hover-bg); } }

	.category {
		font-weight: bold; }

	.dropdown-content {
		display: none;
		position: absolute;
		background-color: var(--menu-bg);
		box-shadow: 0 8px 16px 0 rgba(0,0,0,.5);
		font-size: 90%;
		padding: .15em;
		z-index: 1; } }


div.highlighter-rouge, .box, details {
	background-color: var(--box-bg);
	border: 1px solid var(--border-color);
	padding: .4em 1em;
	margin: 1em 0; }


.pagination {
	text-align: center;

	>* {
		border: 1px solid var(--border-color);
		padding: .2em .5em; } }


.services {
	text-align: right;
	padding: .2em 0;

	>* {
		display: inline-block;
		padding-left: 1ex; } }


.tag-cloud {
	padding: 2em 1em;
	text-align: center;
	background-color: var(--art-bg);

	a {
		margin: 0 .2rem;
		display: inline-block; } }


.thumbs {
	a {
		margin: .1em; } }


.group {
	background-color: var(--art-bg);
	box-shadow: 0 8px 16px 0 rgba(0,0,0,.3);
	padding: 1px 1em 1em;
	margin: 1em 0;

	h2 {
		margin: .2em 0;
		font-size: 1.1em; }

	p {
		margin: .5em 0; }

	.post {
		display: flex;
		padding: .4em;
		border: 1px solid var(--border-color);
		background-color: var(--box-bg);

		>div {
			flex: 1; } } }


.posts-list {
	.post {
		flex-direction: row;
		margin: 1em 0;
		min-height: 7em;

		>a {
			width: 100px;
			height: 75px;
			text-align: center;
			margin-right: .4em;

			img {
				max-height: 100%;
				max-width: 100%;

				&:hover {
					transform: scale(2);
					box-shadow: $box-shadow-hovered; } } } } }


.posts-grid {
	display: grid;
	gap: .3em;
	grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
	margin: .5em 0;

	.post {
		flex-direction: column;
		hyphens: auto;

		>a {
			width: 100%;
			text-align: center;

			img {
				max-width: 100%; } } } }


.icon {
	font-size: 2em; }


.right {
	float: right;
	max-width: 40%;
	margin: 1em; }


ul.markerless {
	list-style-type: none;
	padding-left: 0; }


.hidden, [hidden] {
	display: none; }


.fold-box, .fbox {
	&:not([open]) {
		display: inline;
		margin: 0;
		padding: 0;
		background: transparent;
		border: none; }

	>summary {
		display: block;
		margin: 0;
		padding: 0;
		&::-webkit-details-marker {
			display: none; }

		&::after {
			content: '📄';
			font-size: 2em; } }

	&[open] {
		padding: .5em;
		position: fixed;
		top: 10%;
		left: 10%;
		bottom: 10%;
		right: 10%;
		box-shadow: 0 1em 2em 0 rgba(0,0,0,.7);
		>summary {
			float: right;
			border: none;
			&::after {
				content: 'Закрыть';
				font-size: 1em; } }

		>div {
			padding: .5em;
			position: absolute;
			top: 2em;
			right: .5em;
			bottom: .5em;
			left: .5em;
			overflow: auto;
			background-color: var(--art-bg); } } }


.fbox {
	>summary {
		&::after {
			content: '📚'; } } }


.a-16-9 {
	position: relative;

	&:before {
		padding-top: 56.25%;
		display: block;
		content: ''; }

	>* {
		position: absolute;
		top: 0; } }


.btn {
	border-radius: .2em;
	padding: .2em .4em;
	color: #fff;
	background-color: #d55;
	text-align: center; }


.tooltip {
	display: inline-block;

	&::before {
		content: '✍'; }

	>span {
		display: none;
		position: absolute;
		z-index: 2;
		padding: .5em;
		top: -.5em;
		left: 100%;
		background-color: var(--box-bg);
		border: 1px solid var(--border-color);
		box-shadow: .4em .4em 1em 0 rgba(0,0,0,.7);
		width: max-content; }

	&:hover {
		position: relative;
		>span {
			display: block; } } }


@media(max-width: 50em) {
	.d-sm-none {
		display: none !important; }

	body {
		background-image: none; }

	.container {
		width: 98%; }

	.page-title {
		.right {
			max-width: min-content; } }

	nav.main {
		form {
			float: none;
			text-align: right; } }

	.group {
		hyphens: auto;

		.posts-list {
			display: block;

			.post {
				display: block;

				>a {
					float: left; } } } } }


@media(max-width: 27em) {
	.d-tn-none {
		display: none !important; }

	.page-title {
		display: block;

		>* {
			text-align: center; }

		.right {
			float: none;
			max-width: none; } }

	nav.main {
		form {
			* {
				width: 100%; } } } }


/*** Social buttons ***/

.soc-buttons {
	padding-top: .5em;

	a {
		display: inline-block;
		height: 2em;
		width: 2em;
		background-position: center;
		background-repeat: no-repeat;
		filter: contrast(.1);

		&:hover {
			filter: none; } } }


.btn-vk {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAwIDQ0OCkiIGZpbGw9IiM1ODdlYTMiIGQ9Ik0zOTIgMzAzcTI2LjY1OTYgMCAtNC41IC01MC41cS0zNS40NDQ0IC01Ny40NDQ0IC0zOC41IC02MC41cS00IC02IC00IC0xMC4yNzc4dDQgLTguNzIyMjJsNC41IC01cTQuNSAtNSAxMSAtMTF0MTQgLTE0LjV0MTQgLTE2LjVxMTUuNTQzNSAtMTkuMTMwNCAxOS41IC0zMXE1IC0xNyAtMTQgLTE3aC00MXEtMTIuMjUgMCAtMjQuNSAxNGwtMTAuNSAxMnEtNyA4IC0xNCAxNXEtMjggMjcgLTQxIDI2cS04IDAgLTExIC03dC0xLjUgLTIydDEuNSAtMjIuNXQtNS41IC0xMS41dC0yNC41IC00cS02OSAwIC0xMjYgNzZxLTI0IDMyIC0zOS41IDY0cS0yNS41IDUyLjY0NTIgLTI1LjUgNzNxMCAxMiAxNCAxMmg0MXE4IDAgMTIgLTMuNXQ3IC0xMy41cTE1IC00MyAzNiAtNzYuNXQzMiAtMzMuNXE0IDAgNiAzdDIgMTN2NjFxMCA3IC0zIDE1LjV0LTYgMTEuNXQtNS41IDYuNXQtMi41IDYuNXQxLjUgNXEzLjc1IDUgOS41IDVoNjVxMTEgMCAxMSAtMTZ2LTgxcTAgLTEyIDcgLTEycTE1LjI4NTcgMCA0NSA0OHE4IDEzIDE5IDM1bDcgMTRxNiAxMiAxNyAxMmg0MnpNNDAwIDQxNmgtMzUycS0yMCAwIC0zNCAtMTR0LTE0IC0zNHYtMzUycTAgLTIwIDE0IC0zNHQzNCAtMTRoMzUycTIwIDAgMzQgMTR0MTQgMzR2MzUycTAgMjAgLTE0IDM0dC0zNCAxNHoiLz48L3N2Zz4='); }

.btn-yt {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSJyZWQiIGQ9Ik0xODYuOCAyMDIuMWw5NS4yIDU0LjEtOTUuMiA1NC4xVjIwMi4xek00NDggODB2MzUyYzAgMjYuNS0yMS41IDQ4LTQ4IDQ4SDQ4Yy0yNi41IDAtNDgtMjEuNS00OC00OFY4MGMwLTI2LjUgMjEuNS00OCA0OC00OGgzNTJjMjYuNSAwIDQ4IDIxLjUgNDggNDh6bS00MiAxNzYuM3MwLTU5LjYtNy42LTg4LjJjLTQuMi0xNS44LTE2LjUtMjguMi0zMi4yLTMyLjRDMzM3LjkgMTI4IDIyNCAxMjggMjI0IDEyOHMtMTEzLjkgMC0xNDIuMiA3LjdjLTE1LjcgNC4yLTI4IDE2LjYtMzIuMiAzMi40LTcuNiAyOC41LTcuNiA4OC4yLTcuNiA4OC4yczAgNTkuNiA3LjYgODguMmM0LjIgMTUuOCAxNi41IDI3LjcgMzIuMiAzMS45QzExMC4xIDM4NCAyMjQgMzg0IDIyNCAzODRzMTEzLjkgMCAxNDIuMi03LjdjMTUuNy00LjIgMjgtMTYuMSAzMi4yLTMxLjkgNy42LTI4LjUgNy42LTg4LjEgNy42LTg4LjF6Ii8+PC9zdmc+'); }



@import 'gallery';
@import 'large_banner';
@import 'themes';
