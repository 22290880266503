.large-banner {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 1em;
	background-image: url(/images/facade.jpg);
	background-size: 80%;
	background-repeat: no-repeat;
	background-position-y: center;
	min-height: 18em;
	box-shadow: $box-shadow;

	.content {
		width: 40%;
		text-align: right;
		padding: 0 1em 0 5%;
		background: linear-gradient(to left, var(--art-bg) 50%, transparent 100%); }

	p {
		hyphens: auto;
		margin-left: 10%; } }

@media(max-width: 50em) {
	.large-banner {
		background-size: cover;
		flex-direction: column;
		min-height: 22em;

		.content {
			width: auto;
			height: auto;
			text-align: inherit;
			padding: 2em .5em .2em;
			background: linear-gradient(to top, var(--art-bg) 50%, transparent 100%); }

		p {
			margin-left: 0; } } }
