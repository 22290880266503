/*** gray theme ***/

body.dark {
	--body-bg:       #333;
	--body-fg:       #ddd;
	--link-fg:       #fc0;
	--art-bg:        #444;
	--header-bg:     #555, #555, #444;
	--minor-fg:      #999;
	--menu-bg:       #444;
	--menu-hover-bg: #000;
	--box-bg:        #333;

	background-image: none;
	text-shadow: 0 1px 3px rgba(0,0,0,0.5);

	.breadcrumb {
		color: var(--minor-fg); } }


/*** blue theme ***/

body.blue {
	--body-bg:       #cef;
	--body-fg:       #036;
	--link-fg:       #036;
	--art-bg:        #9be;
	--header-bg:     #9bd, #9ad;
	--minor-fg:      #468;
	--menu-bg:       #cef;
	--menu-hover-bg: transparent;
	--box-bg:        #acf;
	--border-color:  #036;

	background-image: none;

	* {
		text-shadow: none; }

	a:hover, a:focus {
		outline: .2em solid var(--body-fg); }

	.breadcrumb {
		color: var(--minor-fg); } }
