.gallery {
	display: flex;
	padding: 1.5em .5em;
	overflow-x: hidden;

	.card {
		display: flex;
		flex-direction: column;
		width: 17em;
		min-height: 18em;
		padding: .5em;
		background: var(--art-bg);
		filter: saturate(.5);
		box-shadow: $box-shadow;
		transition: .3s;

		img {
			flex: initial;
			max-width: 100%; }

		.header {
			flex: auto;
			padding: .5em 0;
			hyphens: auto; } }

	.card:focus-within, .card:hover {
		transform: translateY(-1em);
		filter: saturate(1);
		box-shadow: $box-shadow-hovered; }

	.card:focus-within ~ .card, .card:hover ~ .card {
		transform: translateX(7em); }

	.card:not(:first-child) {
		margin-left: -7em; } }


@media(max-width: 27em) {
	.gallery {
		overflow-x: auto;
		gap: .5em;

		.card {
			box-shadow: none !important;
			min-width: 17em;
			margin-left: 0 !important;
			transform: none !important; } } }
